
const navarray=[
    {   id:1,
        name:"Designing the future",
        link:'/'
    },
    {   id:2,
        name:"Us",
        link:'/us',
         },
    {   id:3,
       name:"Process",
       link:"/process",
            },
    {   id:4,
        name:"PortFolio",
        link:"/portfolio",
    },
    {   id:5,
        name:"Contact Us",
        link:"/contact",
    },
    
] 

const Pagearray=[
    {   id:1,
        PageId:1,
        isparent:false,
        name:"Designing the future",
        firstTitle:"Let's design the future",
        secondTitle:"Together",
        description:`We build innovative products to enhance human experiences using cutting-edge technologies and processes. Our team of UI/UX specialists and super star developers will make your new mobile application "the next big thing".  
        `
    },
    {   id:2,
        PageId:2,
        isparent:false,
        name:"Us",
        firstTitle:"We are around the",
        secondTitle:"World",
        description:`Codegrabs manages UIUX specialists, software developers, systems architects, business analysts, beta testers and support teams around the world that allow us to offer a complete world-class environment that meets the highest standards allowing projects to be developed in record time at a more affordable cost. We are a team of passionate people committed to the success of projects.`
    },
    {   id:3,
        PageId:3,
        isparent:true,
        name:"Process",
        firstTitle:"Process",
        secondTitle:"Together",
        description:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries
        `
    },
    {   id:4,
        PageId:4,
        isparent:true,
        name:"PortPolio",
        firstTitle:"Endi",
        secondTitle:"Inovationg Productivity",
        description:`Using machine learning real power capabilities and artificial intelligence to guide team management, workload, project organization and productivity, ENDI can raise productivity to the next level.							 
        `
    },
    {   id:5,
        PageId:5,
        isparent:false,
        name:"Contact",
        firstTitle:"Let's design the future",
        secondTitle:"Together",
        description:`Our team of specialists is distributed and organized around the globe, so your projects might be worked 18 hours a day. Contact us today and let's begin your next successful project. `
        
    },
    
] 

const processarray=[
    {   id:1,
        name:"Stage_one",
        firstTitle:"Stage one",
        secondTitle:"Together",
        description:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries
        Lorem Ipsum is simply dummy `
    },
    {   id:2,
        name:"Stage_two",
        firstTitle:"Stage two",
        secondTitle:"Together",
        description:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever`
    },
    {   id:3,
        name:"Stage_three",
        firstTitle:"Stage three",
        secondTitle:"Together",
        description:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries
        `
    },
    {   id:4,
        name:"Stage_four",
        firstTitle:"Stage Four",
        secondTitle:"Together",
        description:`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled `
    }
] 

const Portpolioarray=[
    {   
        id:1,
        name:"Genba",
        firstTitle:"Genba",
        secondTitle:"Inovationg Productivity",
        description:`Genba is a very simple and easy to use mobile application to order delicious and fresh products such as breads, cakes and much more. We want to support you in your day to day and on special occasions up to the door of your home or office. Genba is for everyone, because we all want fresh products every day and for special occasions with a couple of "Pan-Pan and #YaVino"`,
        smalldescription: `Technical interfaces: Backend, frontend, dynamic integrations, credit card tokenization, APIs and WebApp. ML / AI. iOS and Android mobile applications.`,
        siteName: 'Genba',
        siteUrl:'https://genba.online/'

    },
    {   
        id:2,
        name:"Indi",
        firstTitle:"Indidesk",
        secondTitle:"Inovationg Productivity",
        description:`Using machine learning real power capabilities and artificial intelligence to guide team management, workload, project organization and productivity, ENDI can raise productivity to the next level.`,
        smalldescription: `Technical interfaces: Backend, frontend, dynamic integrations, credit card tokenization, APIs and WebApp. ML / AI. iOS and Android mobile applications.`,        
        siteName: 'Endi',
        siteUrl:'http://indidesk.tech/'
    }
] 
export  {navarray,Pagearray,Portpolioarray,processarray};