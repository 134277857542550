import React from 'react'
import { Button, Spin } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import "./home.scss";
import { motion } from "framer-motion"
import TextRightToLeft from '../../components/animateComponents/TextRightToLeft';
import { BlobComponent } from '../../components/animateComponents/BlobComponent/BlobComponent';
// import ImageStack from '../../components/animateComponents/ImageStack';
import ImageStack from "../../components/animateComponents/ImageStack/index";

import NextButton from '../../components/btnComponent/nextButtonComponent'
import LinkNext from '../../components/sharedcomponents/shared/LinkNext/LinkNext';
import { Helmet } from "react-helmet";

const Home = (props) => {
  console.log("props: ", props);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" name="viewport" content="width=device-width, initial-scale=1" />
        <title>home</title>
        <link rel="canonical" href="https://codegrabs.com/" />
      </Helmet>
      <div className="contentSectionLeft">
        <div className="contentSectionLeft-left"></div>

        <div className="contentSectionMiddle homeMiddle" >
          <div className="contentsection" style={{ zIndex: 2 }}>
            {/* <div className="arraowbtn"> <Button className="Backbutton"  icon={<ArrowLeftOutlined/>} shape="circle"></Button> </div>            */}
            <div className="descriptionsectionWrapper">
              <div className="descriptionsection">
                <TextRightToLeft className="SecondHeadline">Let's design the future</TextRightToLeft>
                <TextRightToLeft className="FirstHeadline">Together</TextRightToLeft>
                <TextRightToLeft className="NormalText" style={{ overflowY: 'auto', maxHeight: '300px', paddingvertical: '5%' }}>We build innovative products to enhance human experiences using cutting-edge technologies and processes. Our team of UI/UX specialists and super star developers will make your new mobile application "the next big thing.</TextRightToLeft>
              </div>
            </div>
            <div className="homeNextbutton">
              <LinkNext to={`/us`} />
            </div>
          </div>
        </div>
      </div>
      <div className="contentSectionRight" id="homepage">
        <motion.div
          className="box-right"
          animate={{}}
          transition={{ duration: 2 }}
        >
          <ImageStack />
          <BlobComponent />
        </motion.div>
      </div>
    </>
  )
}
export default Home;