import React from 'react'
import { motion } from "framer-motion"
import PropTypes from 'prop-types'; // ES6

  // Add staggering effect to the children of the container
  const containerVariants = {
    before: {},
    after: { transition: { staggerChildren: 0.06 } },
  }
  const lastLatter = {
    before: {
      opacity: 0,
      x: 50,
      transition: {
          type: "spring",
          damping: 16,
          stiffness: 200,
      },
    },
    after: {
      opacity: 1,
      x: 0,
      transition: {
          type: "spring",
          damping: 16,
          stiffness: 200,
      },
    },
  }
  // Variants for animating each letter
  const letterVariants = {
    before: {
      opacity: 0,
      x: 50,
      transition: {
          type: "spring",
          damping: 16,
          stiffness: 200,
          duration: 2
      },
    },
    after: {
      opacity: 1,
      x: 0,
      transition: {
          type: "spring",
          damping: 16,
          stiffness: 200,
          duration: 2,
      },
    },
  }
const TextOneByOne = (props) => {
    const lastBlink=props.lastBlink;
    const string_Coding_ = Array.from(props.children);
const string_Coding_length = string_Coding_.length-1;
    return (
        <motion.div
        // center={ "y" }
        // height={ 26 }
        width={ "100%" }
        background={ "" }
        style={{
          // fontFamily: "Montserrat, Work Sans, sans-serif",
          fontWeight: "bold",
          letterSpacing: "-0.04em",
          // fontSize: 26,
          color: "#FFF",
          display: "flex", // Set the display value to flex
          // justifyContent: "center", // Center all children elements on the x axis
        }}
        variants={ containerVariants }
        initial={ "before" }
        animate={ "after" }
      >
        {string_Coding_.map((letter, index) => {
            if(string_Coding_length===index &&  lastBlink){
                 return <motion.div
                  key={ index }
                  variants={ lastLatter }
                  width={ "auto" } // Set the width to the width of the letter
                  height={ 26 } // Set the height to the height of the text
                  background={ "" }
                  style={{ position: "relative" }} // Position elements
                  className="FirstHeadline"
                 
                >
                  <motion.div
                  key={ index }
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    ease: "linear",
                    duration: 0.1,
                    flip: Infinity,
                    repeatDelay: 0.5
                  }}
                  width={ "auto" } // Set the width to the width of the letter
                  height={ 26 } // Set the height to the height of the text
                  background={ "" }
                  style={{ position: "relative" }} // Position elements
                  className="FirstHeadline"
                >
                  {letter}
                </motion.div>
                </motion.div>
            }else{
              return (
        
                <motion.div
                  key={ index }
                  width={ "auto" } // Set the width to the width of the letter
                  height={ 26 } // Set the height to the height of the text
                  background={ "" }
                  style={{ position: "relative" }} // Position elements
                  variants={ letterVariants }
                  className="FirstHeadline"
                >
                  {letter}
                </motion.div>
              )
            }
         })}
      </motion.div>
    )
        }

 
        TextOneByOne.defaultProps = {
            lastBlink:false
          };
          TextOneByOne.propTypes={
            // lastBlink need bool value true or false
            // last cherecter can blink if true
            // by default it's false
            lastBlink:PropTypes.oneOf([true,false])
        };

export default TextOneByOne
