import React from 'react';
import './stack.scss';
import { motion } from "framer-motion"
import PropTypes from 'prop-types'; // ES6
const NewImageStack = ({images}) => {
    const container = {
        hidden: { opacity: 0},
        show: {
          opacity: 1,
          // delay:3,
          // rotateX:0,
        //  duration:2,
          transition: {
            staggerChildren: 1,
            delayChildren: 1.5,
            staggerDirection: -1,
            default: { duration: 1},
          }
        }
      }
      const item = {
        hidden: { opacity: 0,rotateX:-90,rotateY:-100 },
        show: {
          opacity: 1,
          rotateX:0,
          rotateY:0,
          // delay:3,

        //  duration:2,
          transition: {
            staggerChildren: 1,
            // delayChildren: 1,
            staggerDirection: -1,
            default: { duration: 1},
          }
        }
      }
      // const item = {
      //   hidden: { opacity: 0 },
      //   show: { opacity: 1 }
      // }
      const exit= {x: -50,opacity:0 }
      
    return (
        <motion.div
        variants={container}
        exit={exit}
    initial="hidden"
    animate="show"
      id="new-imageStackContainer">
            {/* welcome image stack */}
            {images.map((path,index)=>{
               return <motion.img 
                drag
                dragPropagation
                dragConstraints={{ left: 0, right: 0,top:0,bottom:0 }}
     dragElastic={1}
     dragTransition={{ bounceStiffness: 600, bounceDamping: 10 }}
                variants={item}
                exit={exit}
            className="imageStack" src={path} style={{marginTop:-index*30}} />
            })}
        </motion.div>
    )
}

NewImageStack.propTypes={
        /**
     * List of Images as array string
     * ex .[
  require('../../assets/images/processTwo/ico1.png'),
  require('../../assets/images/processTwo/ico2.png'),
  require('../../assets/images/processTwo/ico3.png'),
  require('../../assets/images/processTwo/ico4.png'),
]
     */
    images:PropTypes.array.isRequired,
    direction:PropTypes.oneOf(['left,right']),
};

export default NewImageStack;