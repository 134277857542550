import React from 'react'
import { motion } from "framer-motion";
import { Button, Spin, Row, Col } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import RideCycle from "../animateComponents/RideCycle";
import TextRightToLeft from "../animateComponents/TextRightToLeft";
import WorldMap from "../animateComponents/WorldMap";
import { BlobComponent } from "../animateComponents/BlobComponent/BlobComponent";
import LottieGilr from "../lottieComponents/LottieGilr";
import NextButton from '../btnComponent/nextButtonComponent'
import {Helmet} from "react-helmet";
// import { Link } from 'react-router-dom';
import { ProcessOneRight } from "./ProcessAnimations/index";
import {
  BrowserRouter as Router,
  // Switch,
  // Route,
  Link,
  // useParams,
  useRouteMatch
} from "react-router-dom";
import LinkNext from '../sharedcomponents/shared/LinkNext/LinkNext';

const ProcessOne = () => {
  let { path, url } = useRouteMatch();
  console.log('path: ', path, ' url: ', url);

  return (
    <>
      {/* <Router> */}
      <Col xs={24} sm={24} md={12} lg={12} xl={12} span={12}>
        <Row>
          <Col md={8} lg={8} xl={8}>
          </Col>
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            <Helmet>
              <meta charSet="utf-8" name="viewport" content="width=device-width, initial-scale=1" />
              <title>Planning</title>
              <link rel="canonical" href="https://codegrabs.com/process" />
            </Helmet>
            <div className="contendsection">
              <div className="descriptionsectionWrapper">
                <div className="descriptionsection">
                  <div className="HeadlineSection">
                    <div>
                      <TextRightToLeft className="SecondHeadline">Stage One</TextRightToLeft>
                    </div>
                    <div>
                      <TextRightToLeft className="FirstHeadline">
                        Planning
                      </TextRightToLeft>
                    </div>
                  </div>
                  <TextRightToLeft className="NormalText" style={{ overflowY: 'auto', maxHeight: '300px', paddingvertical: '5%' }}>
                    We listen to your idea and along with our specialists we begin brain-storming all of the capabilities, standards, security and vision of your project so we are ready to discover it's true potential by bringing to the table advanced  cutting-edge technology that will result in flexibility, scalability and performance.
                  </TextRightToLeft>
                </div>
              </div>
              <div className="AboutNextbtn">
                <LinkNext to={`/process/stage-2`} />
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <ProcessOneRight />
      </Col>
    </>
  )
}

export default ProcessOne
