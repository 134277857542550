import React from 'react'
import { motion } from "framer-motion";
import { Button, Spin, Row, Col } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import RideCycle from "../../components/animateComponents/RideCycle";
import TextRightToLeft from "../../components/animateComponents/TextRightToLeft";
import WorldMap from "../../components/animateComponents/WorldMap";
import { BlobComponent } from "../../components/animateComponents/BlobComponent/BlobComponent";
import LottieGilr from "../../components/lottieComponents/LottieGilr";
import NextButton from '../../components/btnComponent/nextButtonComponent'
import NewImageStack from '../animateComponents/NewImageStack';
import LinkNext from '../sharedcomponents/shared/LinkNext/LinkNext';
import LinkBack from '../sharedcomponents/shared/LinkBack/index';
import jsxToString from 'jsx-to-string';
import './processComponents.scss';
import {
  BrowserRouter as Router,
  // Switch,
  // Route,
  Link,
  // useParams,
  useRouteMatch
} from "react-router-dom";
import TextOneByOne from '../animateComponents/TextOneByOne';
import { Helmet } from "react-helmet";

const images = [
  require('../../assets/images/processTwo/ico1.png'),
  require('../../assets/images/processTwo/ico2.png'),
  require('../../assets/images/processTwo/ico3.png'),
  require('../../assets/images/processTwo/ico4.png'),
]
const ProcessThree = () => {
  let { path, url } = useRouteMatch();

  // Create an array of letters
  // const string_Coding_ = Array.from("Coding_");
  // const string_Coding_length = string_Coding_.length-1;
  return (
    <>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Row>
          <Col md={8} lg={8} xl={8}>
            {/* <LottieGilr /> */}
          </Col>
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            <Helmet>
              <meta charSet="utf-8" name="viewport" content="width=device-width, initial-scale=1" />
              <title>Coding</title>
              <link rel="canonical" href="https://codegrabs.com/process/stage-3" />
            </Helmet>
            <div className="contendsection">
              <div className="processBackbtn">
                <LinkBack to={`/process/stage-2`} />
              </div>
              <div className="descriptionsectionWrapper">
                <div className="descriptionsection">
                  <div className="HeadlineSection">
                    <div>
                      <TextRightToLeft className="SecondHeadline">Stage Three</TextRightToLeft>
                    </div>
                    <div>
                      <TextOneByOne lastBlink>Coding_</TextOneByOne>
                    </div>
                  </div>
                  {/* <div className="NormalText"> */}
                  <TextRightToLeft className="NormalText" style={{ overflowY: 'auto', maxHeight: '270px' }}>
                    Our super star developers will begin with all the necessary setup, configurations, logic and coding to bring your project to life. This stage is the most time-consuming in the process but it is the stage that brings dynamic concept, security, scalability and flow to the entire project.
          </TextRightToLeft>
                  {/* </div> */}
                </div>
              </div>
              <div className="AboutNextbtn">
                <LinkNext to={`/portfolio`} />
                {/* <LinkNext to={`${url}/stage-3`} /> */}
              </div>
            </div>
          </Col>

        </Row>

      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>

        <span className="codetext">
          {`
  if (entry.length) {
    // Expose entry point to Node, AMD or browser globals
    // Based on https://github.com/ForbesLindesay/umd/blob/master/template.js
    var mainExports = newRequire(entry[entry.length - 1]);

    // CommonJS
    if (typeof exports === "object" && typeof module !== "undefined") {
      module.exports = mainExports;

    // RequireJS
    } else if (typeof define === "function" && define.amd) {
     define(function () {
       return mainExports;
     });

    // <script>
    } else if (globalName) {
      this[globalName] = mainExports;
    }
  }
  function shouldUseNative() {
    try {
      if (!Object.assign) {
        return false;
      } // Detect buggy property enumeration order in older V8 versions.
      // https://bugs.chromium.org/p/v8/issues/detail?id=4118
  
  
      var test1 = new String('abc'); // eslint-disable-line no-new-wrappers
  
      test1[5] = 'de';
  
      if (Object.getOwnPropertyNames(test1)[0] === '5') {
        return false;
      } // https://bugs.chromium.org/p/v8/issues/detail?id=3056
  
  
      var test2 = {};
  
      for (var i = 0; i < 10; i++) {
        test2['_' + String.fromCharCode(i)] = i;
      }
  
      var order2 = Object.getOwnPropertyNames(test2).map(function (n) {
        return test2[n];
      });
  
      if (order2.join('') !== '0123456789') {
        return false;
      } // https://bugs.chromium.org/p/v8/issues/detail?id=3056
  
  
      var test3 = {};
      'abcdefghijklmnopqrst'.split('').forEach(function (letter) {
        test3[letter] = letter;
      });
  
      if (Object.keys(Object.assign({}, test3)).join('') !== 'abcdefghijklmnopqrst') {
        return false;
      }
  
      return true;
    } catch (err) {
      // We don't expect any of the above to throw, but better to be safe.
      return false;
    }
  }`}</span>

        <BlobComponent isBlack={true} style={{ fill: '#ffc400' }} />
      </Col>
    </>
  )
}

export default ProcessThree