import React from 'react'
import Navbar from './components/sharedcomponents/shared/navbar'
import CustomFooter from './components/sharedcomponents/shared/footer'
// import Container from './components/container'
import { Layout, Menu, Breadcrumb } from 'antd';
import Navigation from './components/Navigation';
// import { Provider, connect } from 'react-redux'
// import store from './store/index';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import './app.css';

const { Header, Content, Footer } = Layout;

const App = () => {
  return (
    // <Provider store={store}>
    <Router>
      <div className={`container`} id="main-container-wrapper" >
        <Layout>
          <Header style={{ position: 'fixed', zIndex: 2, width: '100%' }}>
            <Navbar />
          </Header>

          <Content className="site-layout" >
            <div className="site-layout-background">
              <Navigation />
            </div>
          </Content>

          <Footer style={{
            textAlign: 'center', bottom: 0,
            position: 'fixed',
            width: '100%'
          }}><CustomFooter /></Footer>
        </Layout>
      </div>
    </Router>

  )

}

// const mapStateToProps=(state)=> {

// }

// export default connect(mapStateToProps)(App);
export default App;
