import React,{useState,useEffect} from 'react'
import './BlobCss.css'
import { motion } from "framer-motion"
export const BlobComponent = (props) => {
  const [data,setData]=useState(['M121.7','-181.8C152.1','-169.7','167.5','-126.6','168.9','-87.7C170.4','-48.7','157.9','-13.9','139.1','10.1C120.2','34','94.9','47.1','81.1','79.5C67.4','111.8','65.2','163.4','43','189.8C20.7','216.2','-21.8','217.5','-45.3','192.9C-68.8','168.3','-73.3','117.9','-107.3','87.9C-141.3','57.8','-204.8','48.2','-207.4','29.8C-210.1','11.4','-151.9','-15.7','-126.5','-52.2C-101.2','-88.8','-108.7','-134.8','-93.1','-153.7C-77.5','-172.6','-38.7','-164.3','3.4','-169.6C45.6','-174.9','91.2','-193.9','121.7','-181.8Z'])
    return (
        <>
            {props.isBlack?<motion.div className="blob blob-black"
            // initial={{scale:props.isBlack?0.5:1,opacity:props.isBlack?0.1:1,delay:props.isBlack?3:0}}
            // cx={500}
            // initial={{opacity:0.3}}
            drag
            dragConstraints={{top:0,left:0,right:0,bottom:0}}
            animate={{opacity:1,scale: [1, 1.2, 1.2, 1.5, 1.5,1.5,1.3,1.2], rotate: 360,x: [null, 10, 10,20,20,30,30,40,50,50,40,40,30,30,20,20,10],y:[null,10,10,10,20,20,30,30,30,20,20,10,10],  transition: { duration: 30, loop: Infinity,
              ease: "linear",
              type:'tween'},}}
  exit={{ x:-200,opacity:0, transition: { duration: 1 } }}
            >

          <svg
  // width="600"
  // height="600"
  viewBox="0 0 600 600"
  xmlns="http://www.w3.org/2000/svg"
>
  <g transform="translate(300,300)">
    <path  d="M121.7,-181.8C152.1,-169.7,167.5,-126.6,168.9,-87.7C170.4,-48.7,157.9,-13.9,139.1,10.1C120.2,34,94.9,47.1,81.1,79.5C67.4,111.8,65.2,163.4,43,189.8C20.7,216.2,-21.8,217.5,-45.3,192.9C-68.8,168.3,-73.3,117.9,-107.3,87.9C-141.3,57.8,-204.8,48.2,-207.4,29.8C-210.1,11.4,-151.9,-15.7,-126.5,-52.2C-101.2,-88.8,-108.7,-134.8,-93.1,-153.7C-77.5,-172.6,-38.7,-164.3,3.4,-169.6C45.6,-174.9,91.2,-193.9,121.7,-181.8Z" fill="#000000"
    {...props.style} />
  </g>
</svg>
</motion.div>:<motion.div className="blob blob-pink"
            // initial={{scale:props.isBlack?0.5:1,opacity:props.isBlack?0.1:1,delay:props.isBlack?3:0}}
            // cx={500}
            drag
            dragConstraints={{top:0,left:0,right:0,bottom:0}}
            animate={{opacity:1,scale: [1, 1.2, 1.2, 1.5, 1.5,1.5,1.3,1.2], rotate: [10,50,120,180,360],x: [null, 10, 10,20,20,30,30,40,50,50,40,40,30,30,20,20,10],y:[null,10,10,10,20,20,30,30,30,20,20,10,10],  transition: { duration: 12, loop: Infinity,
              ease: "linear",
              type:'tween'},}}
  exit={{ x:-200,opacity:0, transition: { duration: 1 } }}
            >

         <svg
  viewBox="0 0 600 600"
  xmlns="http://www.w3.org/2000/svg"
>
  <g transform="translate(300,300)">
    <path d="M96,-136.2C133.3,-105.1,178.4,-88.9,193.2,-59.1C208,-29.3,192.4,14.1,180,60.3C167.5,106.5,158.1,155.6,128.6,184C99.1,212.5,49.6,220.2,-0.2,220.5C-50,220.8,-99.9,213.5,-146,190.5C-192.2,167.5,-234.5,128.7,-236.6,85.5C-238.7,42.3,-200.7,-5.3,-181.6,-57.5C-162.5,-109.7,-162.4,-166.5,-135.3,-200.8C-108.2,-235.2,-54.1,-247.1,-12.3,-230.1C29.4,-213.1,58.8,-167.2,96,-136.2Z" fill="#e82967" />
  </g>
</svg>
</motion.div>}
</>
    )
}
