import React, { Component } from 'react'
import { Button, Spin, Row, Col } from 'antd'
import { motion, AnimatePresence } from "framer-motion"
import TextRightToLeft from '../../components/animateComponents/TextRightToLeft';
import { BlobComponent } from '../../components/animateComponents/BlobComponent/BlobComponent';
import CrossImageStack from "../../components/animateComponents/crossImagestack";
import ImageStack from "../../components/animateComponents/ImageStack/index";
import NextButton from '../../components/btnComponent/nextButtonComponent'
import LinkNext from '../../components/sharedcomponents/shared/LinkNext/LinkNext'
import LinkBack from '../../components/sharedcomponents/shared/LinkBack/index'
import { Portpolioarray } from '../../provider'
import './index.scss'
import { Helmet } from "react-helmet";

class Portpolio extends Component {

  constructor(props) {
    super(props);
    this.index = 0,
      this.PortpolioarrayData = Portpolioarray[0],
      this.state = {
        index: 0,
        PortpolioarrayData: Portpolioarray[0],
      }
    this.last_index = Portpolioarray.length - 1;
  }
  gotoUrl = (url) => {
    window.location.Redirect = url;
  }
  setData = (index = 0) => {
    this.index = index;
    this.PortpolioarrayData = this.state.PortpolioarrayData;
    this.PortpolioarrayData = index == 0 || index >= Portpolioarray.length ? Portpolioarray[0] : Portpolioarray[index];
    console.log("PortpolioarrayData: ", this.PortpolioarrayData);
    this.setState({ PortpolioarrayData: this.PortpolioarrayData })
  }

  render() {
    return (
      <motion.div
        exit={{
          y: 30, opacity: 0,
          transition: { duration: 1 },
        }}
      >
        <Helmet>
          <meta charSet="utf-8" name="viewport" content="width=device-width, initial-scale=1" />
          <title>Portfolio</title>
          <link rel="canonical" href="https://codegrabs.com/portfolio" />
        </Helmet>
        <Row className="componentContainer">
          <AnimatePresence exitBeforeEnter onExitComplete={() => {
            this.setState({ index: this.index })

          }}>

            {this.index == this.state.index &&
              <> <Col Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="contentSectionLeft portpolio">
                  <div className="contentSectionLeft-left"></div>

                  <div className="contentSectionMiddle portpolioMiddle" >
                    <div className="contendsection" >
                      {this.state.index > 0 ? <div><LinkBack to={`/portfolio/`} /></div> : <></>}
                      <div className="descriptionsectionWrapper">
                        <div className="descriptionsection">
                          <TextRightToLeft className="FirstHeadline">{this.state.PortpolioarrayData.firstTitle}</TextRightToLeft>
                          <TextRightToLeft className="SecondHeadline">{this.state.PortpolioarrayData.secondTitle}</TextRightToLeft>
                          {/* <div > */}
                          <TextRightToLeft className="NormalText" style={{ overflowY: 'auto', maxHeight: '300px', paddingvertical: '5%' }}>{this.state.PortpolioarrayData.description}</TextRightToLeft>
                          <TextRightToLeft className="SmallText" style={{ marginBottom: '25px' }}>{this.state.PortpolioarrayData.smalldescription}</TextRightToLeft>
                          {/* </div> */}
                        </div>
                      </div>
                      <motion.div
                        initial={{ y: 30, opacity: 0 }}
                        animate={{
                          y: 0, opacity: 1,
                          transition: { delay: 1.2, duration: 1 },
                        }}
                        exit={{
                          y: 30, opacity: 0,
                          transition: { duration: 1 },
                        }}
                        className="arraowbtn" >
                        <Button className="LinkBtn" ><a target="blank" href={this.state.PortpolioarrayData.siteUrl}>{this.state.PortpolioarrayData.siteName}</a></Button>
                      </motion.div>
                    </div>
                  </div>
                </div>
              </Col>
                <Col md={1} lg={1} xl={1}></Col>
                <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                  <div className="contentSectionRight portpolio">
                    {this.state.PortpolioarrayData.name == 'Genba' ? <CrossImageStack /> : <ImageStack />}
                    <div className="portfoliotNextbtn" style={{ marginLeft: this.state.PortpolioarrayData.name == 'Genba' ? '350px' : '400px' }}>
                      {this.last_index != this.state.index ? <NextButton
                        index={this.state.index + 1}
                        setData={this.setData}
                        className="portNextbutton"
                      /> : <LinkNext to={`/contact`} />}
                    </div>
                  </div>
                </Col></>
            }
          </AnimatePresence>
        </Row>
      </motion.div>
    )
  }
}

export default Portpolio;