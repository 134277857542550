import React from 'react'
import { Button } from 'antd'
import { motion } from "framer-motion"
import './index.scss'
import {
  Link
} from "react-router-dom";
const LinkBack = (props) => {

  //   console.log("props: ",props);

  return (
    <motion.div
      initial={{ y: 30, opacity: 0 }}
      animate={{
        y: 0, opacity: 1,
        transition: { delay: 1.2, duration: 1 },
      }}
      exit={{
        y: 30, opacity: 0,
        transition: { duration: 1 },
      }}
      className="BackButtonWrapper"
    >
      <Link
        shape="circle"
        className="BackbtnLink"
        {...props}
        to={props.to}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.4 490.4">
          <path d="M138.7 257.5h183.4l-48 48c-4.8 4.8-4.8 12.5 0 17.3 2.4 2.4 5.5 3.6 8.7 3.6s6.3-1.2 8.7-3.6l68.9-68.9c4.8-4.8 4.8-12.5 0-17.3l-68.9-68.9c-4.8-4.8-12.5-4.8-17.3 0s-4.8 12.5 0 17.3l48 48H138.7c-6.8 0-12.3 5.5-12.3 12.3 0 6.8 5.5 12.2 12.3 12.2z" /></svg>
      </Link>
    </motion.div>
  )
}
export default LinkBack;