import React from 'react'
// import './index.css'

const Footer=()=>{

    return (
        <div>
            codegrab @All rights reserved
        </div>
    )
}

export default Footer;