import React from 'react';
import './stack.css';
import { motion } from "framer-motion"

const ImageStack = () => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      // delay:3,
      // rotateX:0,
      //  duration:2,
      transition: {
        staggerChildren: 1,
        delayChildren: 1.5,
        staggerDirection: -1,
        default: { duration: 1 },
      }
    }
  }
  const item = {
    hidden: { opacity: 0, rotateX: -90, rotateY: -100 },
    show: {
      opacity: 1,
      rotateX: 0,
      rotateY: 0,
      // delay:3,

      //  duration:2,
      transition: {
        staggerChildren: 1,
        // delayChildren: 1,
        staggerDirection: -1,
        default: { duration: 1 },
      }
    }
  }
  // const item = {
  //   hidden: { opacity: 0 },
  //   show: { opacity: 1 }
  // }
  const exit = { x: -50, opacity: 0 }

  return (
    <motion.div
      variants={container}
      exit={exit}
      initial="hidden"
      animate="show"
      className="imageStackContainer">
      {/* welcome image stack */}
      <motion.img
        drag
        dragPropagation
        dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
        dragElastic={1}
        dragTransition={{ bounceStiffness: 600, bounceDamping: 10 }}
        variants={item}
        exit={exit}
        className="imageStack" src={require('../../../assets/images/01.png')} />
      <motion.img
        drag
        dragPropagation
        dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
        dragElastic={1}
        dragTransition={{ bounceStiffness: 600, bounceDamping: 10 }}
        variants={item}
        exit={exit}
        className="imageStack " src={require('../../../assets/images/02.png')} />
      <motion.img
        drag
        dragPropagation
        dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
        dragElastic={1}
        dragTransition={{ bounceStiffness: 600, bounceDamping: 10 }}
        variants={item}
        exit={exit}
        className="imageStack" src={require('../../../assets/images/03.png')} />
      <motion.img
        drag
        dragPropagation
        dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
        dragElastic={1}
        dragTransition={{ bounceStiffness: 600, bounceDamping: 10 }}
        variants={item}
        exit={exit}
        className="imageStack" src={require('../../../assets/images/04.png')} />
      <motion.img
        drag
        dragPropagation
        dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
        // dragElastic={0.2}
        dragTransition={{ bounceStiffness: 600, bounceDamping: 10 }}
        variants={item}
        exit={exit}
        className="imageStack" src={require('../../../assets/images/05.png')} />
    </motion.div>
  )
}
export default ImageStack;