import React from 'react'
import { motion } from "framer-motion"

const TextRightToLeft = (props) => {
    return (<motion.span 
        exit={{x: 50,opacity:0,transition:{duration: 1} }}
        // delay={3}
        initial={{opacity:0,x: 100}}
        animate={{ x:0,opacity:1,transition:{delay:1.2, duration: 1,type: "tween", stiffness: 100} }}
        // drag
        // dragTransition={{ min: 0, max: 0 }}
        className="gray medium" {...props}>{props.children}</motion.span>)
        }

export default TextRightToLeft
