
import React from 'react';
import './index.scss';
import { motion } from "framer-motion"

const CrossImageStack = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          staggerChildren: 1,
          delayChildren: 1.5,
          staggerDirection: -1,
          default: { duration: 1 },
        }
      }}
      className="imageStackPortpolioContainer">
      <motion.img
        initial={{ x: -200, opacity: 0 }}
        animate={{
          x: 0, opacity: 1,
          transition: { delay: 1.2, duration: 1 },
        }}
        exit={{
          y: -200, opacity: 0,
          transition: { delay: 0.5, duration: 0.5 },
        }}

        drag

        className="imageStack" src={require('../../../assets/images/screen1.jpg')} />
      <motion.img
        initial={{ y: -200, opacity: 0 }}
        animate={{
          y: 0, opacity: 1, rotate: 360,
          transition: { delay: 1.2, duration: 1 },
        }}
        exit={{
          x: -200, opacity: 0,
          transition: { delay: 0.5, duration: 0.5 },
        }}
        drag
        className="imageStack " src={require('../../../assets/images/01.png')} />

    </motion.div>

  )
}
export default CrossImageStack;