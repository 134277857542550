import React from 'react'
import { Button, Spin, Row, Col } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { motion } from "framer-motion"
import TextRightToLeft from '../../components/animateComponents/TextRightToLeft';
import { BlobComponent } from '../../components/animateComponents/BlobComponent/BlobComponent';
import './contact.scss'
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

const Contact = (props) => {

    console.log("props: ", props);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" name="viewport" content="width=device-width, initial-scale=1" />
                <title>Contact Us</title>
                <link rel="canonical" href="https://codegrabs.com/contact" />
            </Helmet>
            <Row className="componentContainer" id="contact-page">
                <Col xs={24} sm={24} md={11} lg={11} xl={11}>

                    <div className="contentSectionLeft contact">
                        <div className="contentSectionLeft-left"></div>

                        <div className="contentSectionMiddle contactMiddle" >
                            <div className="contendsection" >
                                <div className="descriptionsectionWrapper">
                                    <div className="descriptionsection">
                                        <TextRightToLeft className="SecondHeadline">Let's design the future</TextRightToLeft>
                                        <TextRightToLeft className="FirstHeadline">Together</TextRightToLeft>
                                        <TextRightToLeft className="NormalText" style={{ overflowY: 'auto', maxHeight: '300px', paddingvertical: '5%' }}>
                                            Our team of specialists is distributed and organized around the globe, so your projects might be worked 18 hours a day. Contact us today and let's begin your next successful project.
                                  </TextRightToLeft>

                                    </div>
                                </div>
                                <motion.div
                                    initial={{ y: 30, opacity: 0 }}
                                    animate={{
                                        y: 0, opacity: 1,
                                        transition: { delay: 1.2, duration: 1 },
                                    }}
                                    exit={{
                                        y: 30, opacity: 0,
                                        transition: { duration: 1 },
                                    }}
                                    className="arraowbtn" >
                                    <Link to="/portfolio"><Button className="LinkBtn"><span className="LinkBtnText">Portpolio</span></Button></Link>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={2} lg={2} xl={2} className="verticalLine">
                </Col>
                <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                    <div className="contentSectionRight contact">
                        <motion.div
                            className="box-right"
                            style={{ paddingTop: '20vh' }}
                            animate={{}}
                            transition={{ duration: 2 }}
                        >
                            <TextRightToLeft className="SmallText">
                                Drop us a line
                            </TextRightToLeft>
                            <TextRightToLeft className="SecondHeadline emailtag" >
                                {/* help@codegrabs.com */}
                                <a href="#">help@codegrabs.com</a>
                            </TextRightToLeft>
                            <TextRightToLeft className="address-text">
                                Plot no 75, Thakhare house, 1st floor, Nandanvan layout, behind gananayak hall
                            </TextRightToLeft>
                            <TextRightToLeft className="address-text">
                                Pin Code: 440009
                            </TextRightToLeft>
                            <TextRightToLeft className="address-text">
                                Contact: 8668371248
                            </TextRightToLeft>

                        </motion.div>
                        {/* <BlobComponent /> */}
                        <BlobComponent isBlack={true} />
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default Contact;