import React from 'react'
import { motion } from "framer-motion";
import { Button, Spin, Row, Col } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import RideCycle from "../../components/animateComponents/RideCycle";
import TextRightToLeft from "../../components/animateComponents/TextRightToLeft";
import WorldMap from "../../components/animateComponents/WorldMap";
import { BlobComponent } from "../../components/animateComponents/BlobComponent/BlobComponent";
import LottieGilr from "../../components/lottieComponents/LottieGilr";
import NextButton from '../../components/btnComponent/nextButtonComponent'
import NewImageStack from '../animateComponents/NewImageStack';
import LinkNext from '../sharedcomponents/shared/LinkNext/LinkNext';
import LinkBack from '../sharedcomponents/shared/LinkBack/index';
import {
  BrowserRouter as Router,
  // Switch,
  // Route,
  Link,
  // useParams,
  useRouteMatch
} from "react-router-dom";
import { Helmet } from "react-helmet";

const images = [
  require('../../assets/images/processTwo/ico1.png'),
  require('../../assets/images/processTwo/ico2.png'),
  require('../../assets/images/processTwo/ico3.png'),
  require('../../assets/images/processTwo/ico4.png'),
]
const ProcessTwo = () => {
  let { path, url } = useRouteMatch();
  console.log('path: ', path, ' url: ', url);

  return (
    <>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Row>
          <Col md={8} lg={8} xl={8}>
            {/* <LottieGilr /> */}
          </Col>
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            <Helmet>
              <meta charSet="utf-8" name="viewport" content="width=device-width, initial-scale=1" />
              <title>UI/UX</title>
              <link rel="canonical" href="https://codegrabs.com/process/stage-2" />
            </Helmet>
            <div className="contendsection">
              <div className="processBackbtn">
                <LinkBack to={`/process`} />
              </div>
              <div className="descriptionsectionWrapper">
                <div className="descriptionsection">
                  <div className="HeadlineSection">
                    <div>
                      <TextRightToLeft className="SecondHeadline">Stage Two</TextRightToLeft>
                    </div>
                    <div>
                      <TextRightToLeft className="FirstHeadline">
                        UI / UX
                      </TextRightToLeft>
                    </div>
                  </div>
                  {/* <div className="NormalText"> */}
                  <TextRightToLeft className="NormalText" style={{ overflowY: 'auto', maxHeight: '270px' }}>
                    Our specialized team of UI/UX designers will work on a unique visual concept graphic line matching the proposed process flow chart, the vision, the technical requirements and a perfect look'n'feel for your next successful project. Once done, we will present it to you for your approval.
                  </TextRightToLeft>
                  {/* </div> */}
                </div>
              </div>
              <div className="AboutNextbtn">
                <LinkNext to={`/process/stage-3`} />
              </div>
            </div>
          </Col>

        </Row>

      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        {/* <BlobComponent  /> */}
        <NewImageStack images={images} />
      </Col>
    </>
  )
}

export default ProcessTwo