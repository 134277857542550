import React from 'react'
import  "./index.scss";
import TextRightToLeft from '../../components/animateComponents/TextRightToLeft';

const NotFound = () => {
    return (
        <div id="NotFound"> 
            <TextRightToLeft>Not match with route, are you looking for?</TextRightToLeft>
        </div>
    )
}

export default NotFound
