import React,{useState} from 'react'
import Lottie from 'react-lottie';
import * as girlLottie from "../../assets/lottie/lottie-test.json";
const LottieGilr = () => {
    const [isStopped,isStoppedSet]=useState(false);
    const [isPaused,isPausedSet]=useState(false);

    const buttonStyle = {
        display: 'block',
        margin: '10px auto'
      };
  
      const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: girlLottie,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };
    return (
        <div>
        <Lottie options={defaultOptions}
              height={400}
              width={400}
              isStopped={isStopped}
              isPaused={isPaused}/>
      <button style={buttonStyle} onClick={() => {isStoppedSet(true)}}>stop</button>
      <button style={buttonStyle} onClick={() => {isStoppedSet(false)}}>play</button>
      <button style={buttonStyle} onClick={() => {isPausedSet(!isPaused)}}>pause</button>
        </div>
    )
}

export default LottieGilr
