import React, { Component } from "react";
import "./about.css";
import { motion } from "framer-motion";
import { Button, Spin, Row, Col } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import RideCycle from "../../components/animateComponents/RideCycle";
import TextRightToLeft from "../../components/animateComponents/TextRightToLeft";
import WorldMap from "../../components/animateComponents/WorldMap";
import { BlobComponent } from "../../components/animateComponents/BlobComponent/BlobComponent";
import LottieGilr from "../../components/lottieComponents/LottieGilr";
import NextButton from '../../components/btnComponent/nextButtonComponent'
import LinkNext from '../../components/sharedcomponents/shared/LinkNext/LinkNext'
import { Helmet } from "react-helmet";

const About = (props) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" name="viewport" content="width=device-width, initial-scale=1" />
        <title>About Us</title>
        <link rel="canonical" href="https://codegrabs.com/us" />
      </Helmet>
      <Row className="componentContainer">
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Row>
            <Col xs={4} sm={4} md={8} lg={8} xl={8}>
              {/* <LottieGilr /> */}
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
              <div className="contendsection aboutsection" style={{ zIndex: 9999 }}>
                {/* <div className="arraowbtn">
      {" "}
      <Button
        className="Backbutton"
        icon={<ArrowLeftOutlined />}
        shape="circle"
      ></Button>{" "}
    </div> */}
                <div className="descriptionsectionWrapper">
                  <div className="descriptionsection">
                    <div className="HeadlineSection">
                      <div>
                        <TextRightToLeft className="SecondHeadline">We are around the</TextRightToLeft>
                      </div>
                      <div>
                        <TextRightToLeft className="FirstHeadline">
                          World
            </TextRightToLeft>
                      </div>
                    </div>
                    {/* <div className="NormalText"> */}
                    <TextRightToLeft className="NormalText" style={{ overflowY: 'auto', maxHeight: '300px', paddingvertical: '5%' }}>
                      Codegrabs manages UIUX specialists, software developers, systems architects, business analysts, beta testers and support teams around the world that allow us to offer a complete world-class environment that meets the highest standards allowing projects to be developed in record time at a more affordable cost. We are a team of passionate people committed to the success of projects.
          </TextRightToLeft>
                    {/* </div> */}
                  </div>
                </div>
                <div className="AboutNextbtn">
                  {/* <NextButton /> */}
                  <LinkNext to={`/process`} />
                </div>
              </div>
            </Col>

          </Row>

        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <WorldMap />
        </Col>
      </Row>
    </>
  );

}


export default About;