import React, { useState } from "react";
import "./index.scss";
// import { motion } from "framer-motion";
import { Button, Spin, Row, Col } from 'antd'
import { ProcessOne, ProcessTwo, ProcessThree } from "../../components/processComponents";
import { motion, AnimatePresence } from "framer-motion"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { Helmet } from "react-helmet";

// import { ArrowLeftOutlined,ArrowRightOutlined } from '@ant-design/icons';
// import RideCycle from "../../components/animateComponents/RideCycle";
// import TextRightToLeft from "../../components/animateComponents/TextRightToLeft";
// import WorldMap from "../../components/animateComponents/WorldMap";
// import { BlobComponent } from "../../components/animateComponents/BlobComponent/BlobComponent";
// import LottieGilr from "../../components/lottieComponents/LottieGilr";
// import NextButton from '../../components/btnComponent/nextButtonComponent'
// import { processarray } from "../../provider/index";

const Process = (props) => {
  //  const [page,setPage]=useState(0);
  let { path, url } = useRouteMatch();
  console.log('path: ', path, ' url: ', url);
  const navarray = [
    { id: 0, name: "Designing the future", link: "/process", component: ProcessOne },
    { id: 1, name: "Designing the future", link: `${path}/stage-2`, component: ProcessTwo },
    { id: 2, name: "process 3", link: `${path}/stage-3`, component: ProcessThree },
  ];
  console.log('navarray: ', navarray);

  return (
    <motion.div
      exit={{
        y: 30, opacity: 0,
        transition: { duration: 1 },
      }}>
      <Row className="componentContainer" id="process-page">
        <Helmet>
          <meta charSet="utf-8" name="viewport" content="width=device-width, initial-scale=1" />
          <title>Process</title>
          <link rel="canonical" href="https://codegrabs.com/process" />
        </Helmet>
        <Route
          render={({ location }) => {
            console.log("Process location: ", location);
            // pagenameUpdate(location);
            //  props.pageName(pageName);
            return (
              <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.pathname}>
                  {navarray.map((page, i) => (
                    <Route
                      key={i}
                      exact={page.link == '/process' ? true : false}
                      path={page.link}
                      component={page.component}
                    />
                  ))}
                </Switch>
              </AnimatePresence>
            );
          }}
        />
      </Row>
    </motion.div>
  );

}


export default Process;