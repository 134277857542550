import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Home from "../../views/Home";
import About from "../../views/About";
import Contact from "../../views/Contact";
import Portpolio from "../../views/Portpolio";
import NotFound from "../../views/NotFound";
import Process from "../../views/Process";
const navarray = [
  { id: 0, name: "Designing the future", link: "/", component: Home },
  { id: 1, name: "Designing the future", link: "/home", component: Home },
  { id: 2, name: "Us", link: "/us", component: About },
  { id: 3, name: "Process", link: "/process", component: Process },
  { id: 4, name: "PortFolio", link: "/portfolio", component: Portpolio },
  { id: 5, name: "Contact Us", link: "/contact", component: Contact },
  { id: 6, name: "Not found", link: "*", component: NotFound },
];

const Navigation = () => {
  useEffect(() => {
    pagenameUpdate(location);
    console.log("Navigation useEffect");
  });
  const pagenameUpdate = (location) => {
    const mainWrapper = document.getElementById("main-container-wrapper");
    // console.log('mainWrapper: ',mainWrapper);
    if (mainWrapper) {
      let pageName = "";
      switch (location.pathname) {
        case "/contact":
          pageName = "contact";
          break;

        case "/us":
          pageName = "us";

          break;

        case "/home":
        case "/":
          pageName = "home";
          break;
        case "/process":
          pageName = "process";
          break;
        case "/portfolio":
          pageName = "portfolio";
          break;
        default:
          break;
      }
      mainWrapper.setAttribute("pageName", pageName);
    }
  };
  return (
    <div className="contentSection">
      <Route
        render={({ location }) => {
          console.log("location: ", location);
          pagenameUpdate(location);
          return (
            <AnimatePresence exitBeforeEnter>
              <Switch location={location} key={location.pathname}>
                {navarray.map((page, i) => (
                  <Route
                    key={i}
                    exact={page.link == '/' || page.link == 'home' ? true : false}
                    path={page.link}
                    component={page.component}
                  />
                ))}
              </Switch>
            </AnimatePresence>
          );
        }}
      />
    </div>
  );
};

export default Navigation;
